import React, {useEffect, useRef, useState} from "react";
import {
  getCompanies,
  getCompany,
  setCompany,
  deleteCompany,
  getCompanyCount,
} from "../../services/address-book";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";
import { useDictionary } from "../../services/dictionary";
import { getAutocompleteCities } from "../../services/position";
import CompanyNotificationTemplate from "../../modules/hcmd/components/CompanyNotificationTemplate";
import { Tabs, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
const _ = require("lodash");

Companies.propTypes = {};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function Companies(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);
  const [filter, setFilter] = useState('');
  const [listFilter, setListFilter] = useState({});

  const permissions = usePermission("company");

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [tab, setTab] = useState(0);

  const emptyOptions = {
    countries: [],
    counties: [],
    fieldsOfWorkCategories: [],
  };

  const dictionaryOptions = Object.assign({}, emptyOptions);
  dictionaryOptions.countries = useDictionary("countries", [
    {
      order: ["priority ASC"],
      fields: ["id", "name", "nationality"],
    },
  ]);
  dictionaryOptions.counties = useDictionary("counties");
  dictionaryOptions.fieldsOfWorkCategories = useDictionary(
    "fieldsOfWorkCategories"
  );

  useEffect(() => {
    let isAllLoaded = true;
    Object.keys(dictionaryOptions).map((attr) => {
      if (!dictionaryOptions[attr] || !dictionaryOptions[attr].length) {
        isAllLoaded = false;
      }
    });
    if (loading && isAllLoaded) {
      setOptions(dictionaryOptions);
      setLoading(false);
    }
  }, [dictionaryOptions]);

  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  let isEditable = false;
  if (id && permissions.includes("update")) {
    isEditable = true;
  } else if (!id && permissions.includes("create")) {
    isEditable = true;
  }

  const [columns, setColumns] = useState([]);

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const [city, setCity] = useState("");

  useEffect(() => {
    if (id) {
      getCompany(id)
        .then((response) => setCity(response.data?.city ?? 0))
        .catch((err) => console.error(err));
    } else {
      setCity("");
    }
    // getCities()
    //   .then((response) => {
    //     setCities(response.data);
    //     // console.log("setCities", isForm, response.data);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
    return () => {
      setTab(0);
    }
  }, [id]);

  // ide jon az oszlopok deklaralasa
  useEffect(() => {
    if (options) {
      setColumns([
        {
          field: "id",
          fieldType: "number",
          hide: true,
          submitGetter: (value) => {
            return id ? id : null;
          },
        },
        {
          field: "name",
          fieldType: "text",
          headerName: "Név",
          validation: {
            required: true,
            minLength: 3,
          },
        },
        {
          field: "isActive",
          fieldType: "boolean",
          defaultValue: true,
          headerName: "Státusz",
          width: 100,
          valueGetter: (params) => {
            return params.row.isActive ? "aktív" : "inaktív";
          },
        },
        {
          field: "phone",
          fieldType: "text",
          headerName: "Telefonszám",
        },
        {
          field: "email",
          fieldType: "text",
          headerName: "Adatkezelési e-mail cím",
          validation: {
            required: true,
          },
        },
        // {
        //   field: "fax",
        //   fieldType: "text",
        //   headerName: "Fax",
        //   hide: true,
        // },
        {
          field: "website",
          fieldType: "text",
          headerName: "Weboldal",
        },
        {
          field: "countryId",
          fieldType: "select",
          headerName: "Ország",
          hide: true,
          defaultValue: 61,
          valueGetter: (params) => {
            if (params.row?.countryId) {
              let v = `ismeretlen (${params.row?.countryId})`;
              if (options.countries.length) {
                const country = options.countries.find(
                  (item) => item.id === params.row?.countryId
                );
                if (country) {
                  v = country.name;
                }
              }
              return v;
            } else {
              return params.row;
            }
          },
        },
        {
          field: "countyId",
          fieldType: "select",
          headerName: "Megye",
          hide: true,
          valueGetter: (params) => {
            if (params.row?.countyId) {
              let v = `ismeretlen (${params.row?.countyId})`;
              if (options.counties.length) {
                const county = options.counties.find(
                  (item) => item.id === params.row?.countyId
                );
                if (county) {
                  v = county.name;
                }
              }
              return v;
            } else {
              return params.row;
            }
          },
          submitGetter: (value) => {
            console.log('countyId', value);
            return value ? value : null;
          },
        },
        {
          field: "fullAddress",
          fieldType: "",
          headerName: "Cím",
          valueGetter: (params) => {
            if (params.row) {
              return `${params.row.zip ?? ""} ${params.row.city ?? ""}, ${
                params.row.address
              }`;
            }
            return "";
          },
          submitGetter: (value) => {
            return null;
          },
        },
        {
          field: "city",
          fieldType: "autocomplete",
          headerName: "Település",
          getFunc: getAutocompleteCities,
          getParams: { original: city ?? "" },
          valueAttr: "name",
          labelAttr: "name",
          validation: {
            required: true,
          },
          valueGetter: (params) => {
            if (params.row?.city) {
              return params.row?.city?.name ?? params.row?.city ?? "";
            } else if (typeof params.row === "string") {
              return params.row;
            }
            return '';
          },
          submitGetter: (value) => {
            return value.name ? value.name : value;
          },
        },
        {
          field: "address",
          fieldType: "text",
          headerName: "Utca, házszám",
          hide: true,
        },
        {
          field: "registrationNumber",
          fieldType: "text",
          headerName: "Cégjegyzékszám",
          hide: true,
        },
        {
          field: "taxNumber",
          fieldType: "text",
          headerName: "Adószám",
          hide: true,
        },
        {
          field: "bankAccountNumber",
          fieldType: "text",
          headerName: "Bankszámla szám",
          hide: true,
        },
        {
          field: "phone_1",
          fieldType: "text",
          headerName: "Mobilszám",
          hide: true,
        },
        {
          field: "fieldsOfWorkCategories",
          fieldType: "checkbox",
          headerName: "Iparág",
          hide: true,
          validation: {
            required: true,
          },
          valueGetter: (params) => {
            console.log("fieldsOfWorkCategories", params);
            if (params.row) {
              return params.row.map((item) => item.name).join(", ");
            }
            return [];
          },
          submitGetter: (value) => {
            return value;
            // console.log("field", value);
            // const valueArr = [];
            // value.map((item) => {
            //   valueArr.push({
            //     fieldsOfWorkCategoryId: item.id,
            //   });
            // });
            // return valueArr;
          },
        },
      ]);
    }
    return () => {
      setColumns([]);
    };
  }, [id, isForm, city, options]);

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Cégek",
    url: "/address-books/companies",
    item: "cég",
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const refTimeout = useRef(null);

  const handleQuickfilter = (value) => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
      refTimeout.current = null;
    }
    refTimeout.current = setTimeout(() => {
      setFilter(value);
    }, 100);
  }

  useEffect(() => {
    const where = {};
    if (filter.toString() !== '') {
      const like = `%${filter}%`;
      where.or = [
        {name: {like: like}},
        {city: {like: like}},
        {address: {like: like}},
      ];
    }
    setListFilter(where);
  }, [filter]);

  return (
    <>
      {options && isForm && (
        <>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tab} onChange={handleChange} aria-label="Companies">
                <Tab label="Szerkesztés" {...a11yProps(0)} />
                {id > 0 && (<Tab label="Értesítések" {...a11yProps(1)} />)}
              </Tabs>
            </Box>
            <TabPanel index={0} value={tab}>
              <Forms
                editable={isEditable}
                id={id}
                header={header}
                permissions={permissions}
                columns={columns}
                getFunc={getCompany}
                setFunc={setCompany}
                // cities={cities}
                counties={options.counties}
                countries={options.countries}
                fieldsOfWorkCategories={options.fieldsOfWorkCategories}
              ></Forms>
            </TabPanel>
            {id > 0 && (<TabPanel index={1} value={tab}>
              <CompanyNotificationTemplate
                companyId={id}
              ></CompanyNotificationTemplate>
            </TabPanel>)}
          </Box>
        </>
      )}
      {options && !isForm && (
        <Lists
          header={header}
          permissions={permissions}
          isDictionary={true}
          getFunc={getCompanies}
          getCountFunc={getCompanyCount}
          deleteFunc={deleteCompany}
          quickfilterFunc={handleQuickfilter}
          getParams={listFilter}
          columns={columns}
          // cities={cities}
          counties={options.counties}
          countries={options.countries}
          fieldsOfWorkCategories={options.fieldsOfWorkCategories}
        ></Lists>
      )}
    </>
  );
}

export default Companies;
