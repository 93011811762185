import React, { useEffect, useRef, useState } from "react";
import {
  getEmployees,
  getEmployee,
  setEmployee,
  deleteEmployee,
  getEmployeeCount,
  getOffices,
} from "../../services/address-book";
import Lists from "../../modules/hcmd/components/Lists";
import { Link, useNavigate, useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import {
  getPossibleRoles,
  roles,
  usePermission,
  userOffices,
  userRoles,
} from "../../services/auth";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Typography,
} from "@mui/material";
import { roleNames } from "../../services/user";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { LinearProgressWithLabel } from "../../services/constants";

Employees.propTypes = {};

function Employees(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);
  const formRefs = useRef([]);

  const permissions = usePermission("employee");

  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  const navigate = useNavigate();

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const [user, setUser] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [formColumns, setFormColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [possibleRoles, setPossibleRoles] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [offices, setOffices] = useState([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    getPossibleRoles().then((response) => {
      setPossibleRoles(response.data.map((item) => item.name));
    });
    return () => {
      setUser(null);
      setIsForm(false);
      setIsEditable(false);
    };
  }, []);

  useEffect(() => {
    if (selectedRoles.includes('hca_c')) {
      setSelectedRoles(['hca_c']);
    }
  }, [selectedRoles]);

  useEffect(() => {
    if (isForm) {
      getOffices().then((response) => {
        setOffices(response.data);
      });
      if (id) {
        getEmployee(id).then((response) => {
          setUser(response.data);
        });
      } else if (isForm) {
        setUser({
          id: 0,
        });
        setIsEditable(true);
      }
    }
    return () => {
      setUser(null);
      setIsForm(false);
      setIsEditable(false);
    }
  }, [isForm, id]);

  useEffect(() => {
    if (id > 0 && possibleRoles.length > 0) {
      userRoles(id)
        .then((response) => {
          setSelectedRoles(
            response.data.filter((item) => possibleRoles.includes(item))
          );
        })
        .catch((err) => console.error(err));
    }
  }, [possibleRoles, id]);

  useEffect(() => {
    if (id > 0 && offices.length > 0) {
      userOffices(id)
        .then((response) => {
          setSelectedOffices(response.data);
        })
        .catch((err) => console.error(err));
    }
  }, [offices, id]);

  useEffect(() => {
    // ide jon az oszlopok deklaralasa
    setColumns([
      {
        field: "id",
        fieldType: "number",
        hide: true,
      },
      {
        field: "email",
        fieldType: "text",
        headerName: "E-mail",
        validation: {
          required: true,
          minLength: 3,
        },
      },
      {
        field: "name",
        fieldType: "hidden",
        headerName: "Név",
        valueGetter: (params) => {
          return `${params.row.lastName} ${params.row.firstName}`;
        },
      },
      {
        field: "isActive",
        fieldType: "boolean",
        defaultValue: true,
        headerName: "Státusz",
        width: 100,
        valueGetter: (params) => {
          return params.row.isActive && params.row.status === 30
            ? "aktív"
            : params.row.status === 40 && !params.row.isActive ? "törölt"  : "inaktív";
        },
      },
      {
        field: "role",
        fieldType: "select",
        headerName: "Jogosultság",
        width: 100,
        valueGetter: (params) => {
          return "";
        },
      },
    ]);
    setFormColumns([
      [
        {
          field: "id",
          fieldType: "number",
          hide: true,
        },
        {
          field: "email",
          fieldType: "text",
          headerName: "E-mail",
          validation: {
            required: true,
            minLength: 3,
          },
        },
        {
          field: "changePassword",
          fieldType: "password",
          headerName: "Jelszó",
          validation: {
            required: true,
            minLength: 6,
          },
        },
        {
          field: "isActive",
          fieldType: "boolean",
          defaultValue: true,
          headerName: "Státusz",
          width: 100,
          valueGetter: (params, values) => {
            return values.row.isActive && values.row.status === 30 ? "aktív" : "inaktív";
          },
        },
      ],
      [
        {
          field: "title",
          fieldType: "text",
          fieldModel: "userEmployee",
          hide: true,
          headerName: "Titulus",
        },
        {
          field: "lastName",
          fieldType: "text",
          hide: true,
          headerName: "Vezetéknév",
        },
        {
          field: "firstName",
          fieldType: "text",
          hide: true,
          headerName: "Keresztnév",
        },
        {
          field: "phone",
          fieldType: "text",
          fieldModel: "userEmployee",
          headerName: "Telefonszám",
          hide: true,
        },
        {
          field: "mobile",
          fieldType: "text",
          fieldModel: "userEmployee",
          headerName: "Mobilszám",
          hide: true,
        },
      ],
      [
        {
          field: "roles",
          fieldType: "hidden",
        },
        {
          field: "offices",
          fieldType: "hidden",
        },
      ],
      [
        {
          field: "picture",
          fieldType: "upload",
          headerName: "Profilkép",
        },
      ],
    ]);
  }, [possibleRoles, user]);

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Felhasználók",
    url: "/address-books/employees",
    item: "felhasználó",
    getRowClassName: (params) => {
      if (params.row.status === 40 && !params.row.isActive) {
        return "row-deleted";
      } else if (params.row.status === 40 || !params.row.isActive) {
        return "row-inactive";
      }
    },
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleOpenNew = () => {
    setUser({ id: 0 });
    setIsForm(false);
    setIsEditable(false);
    navigate("/address-books/employees/0");
  };

  const handleSave = () => {
    setSaving(true);

    let values = {};
    let errors = {};

    const merge = (cur, acc) => {
      Object.keys(acc).map((attr) => {
        if (!cur[attr]) {
          cur[attr] = acc[attr];
        } else if (Array.isArray(acc[attr]) && acc[attr].length > 0) {
          acc[attr].map((item, index) => {
            if (!cur[attr][index]) {
              cur[attr][index] = acc[attr][index];
            } else {
              cur[attr][index] = { ...cur[attr][index], ...item };
            }
          });
        } else if (Object.keys(acc[attr]).length > 0) {
          Object.keys(acc[attr]).map((key) => {
            if (!cur[attr][key]) {
              cur[attr][key] = acc[attr][key];
            } else {
              cur[attr][key] = { ...cur[attr][key], ...acc[attr][key] };
            }
          });
        }
      });
      return cur;
    };

    formRefs.current.map((form) => {
      if (form && form.submit) {
        const formValue = form.submit();
        const formErrors = form.validation();
        errors = { ...errors, ...formErrors };
        // values = { ...values, ...formValue };
        values = merge(values, formValue);
      } else {
        console.log("missing");
      }
    });
    values.roles = selectedRoles;
    values.offices = selectedOffices;

    setError('');
    setEmployee(id, values)
      .then((response) => {
        console.log('setEmployee', response);
        if (response.data.success && response.data.employee) {
          if (!id) {
            navigate(`/address-books/employees/${response.data.employee.id}`);
          } else {
            setSaving(false);
            setIsEditable(!isEditable);
            setUser(null);
            navigate(`/address-books/employees`);
          }
        } else {
          setError(response.data.msg);
          setSaving(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response?.data?.error?.message ?? 'Váratlan hiba történt!');
        setSaving(false);
      });
  };

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  return (
    <>
      {columns.length && (
        <>
          {isForm && user && (
            <Grid container direction={"column"}>
              <Grid>
                <h3>Bejelentkezési adatok</h3>
                <hr />
                <Forms
                  isDense={true}
                  editable={isEditable}
                  header={{ skip: true }}
                  permissions={["create", "read", "update", "delete"]}
                  columns={formColumns[0]}
                  getObj={user}
                  ref={(el) => (formRefs.current[0] = el)}
                  submitFunc={(values) => handleSubmit(values)}
                ></Forms>
              </Grid>
              <Grid>
                <h3>Elérhetőség</h3>
                <hr />
                <Forms
                  isDense={true}
                  editable={isEditable}
                  header={{ skip: true }}
                  permissions={["create", "read", "update", "delete"]}
                  columns={formColumns[1]}
                  getObj={user}
                  ref={(el) => (formRefs.current[1] = el)}
                  submitFunc={(values) => handleSubmit(values)}
                ></Forms>
              </Grid>
              <Grid>
                <h3>Beállítások</h3>
                <hr />
                {!!possibleRoles.length && (
                  <>
                    <h4>Jogosultságcsoport</h4>
                    <Grid container>
                      {possibleRoles.map((item, index) => {
                        const role = roleNames.find(
                          (roleName) => roleName.id === item
                        );
                        if (role) {
                          return (
                            <Grid key={index}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedRoles.includes(item)}
                                      disabled={!isEditable}
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          setSelectedRoles([
                                            ...selectedRoles,
                                            item,
                                          ]);
                                        } else {
                                          setSelectedRoles(
                                            selectedRoles.filter(
                                              (selected) => selected !== item
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  }
                                  label={role.name}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  </>
                )}
                <hr />
                {!!offices.length && (
                  <>
                    <h4>Irodák</h4>
                    <Grid container>
                      {offices.map((item, index) => (
                        <Grid key={index}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!isEditable}
                                  checked={
                                    !!selectedOffices.filter(
                                      (selected) => selected.id === item.id
                                    ).length
                                  }
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      setSelectedOffices([
                                        ...selectedOffices,
                                        item,
                                      ]);
                                    } else {
                                      setSelectedOffices(
                                        selectedOffices.filter(
                                          (selected) => selected.id !== item.id
                                        )
                                      );
                                    }
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          </FormGroup>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                <Forms
                  isDense={true}
                  editable={isEditable}
                  header={{ skip: true }}
                  permissions={["create", "read", "update", "delete"]}
                  columns={formColumns[2]}
                  getObj={user}
                  ref={(el) => (formRefs.current[2] = el)}
                  submitFunc={(values) => handleSubmit(values)}
                ></Forms>
              </Grid>
              <Grid>
                <h3>Egyéb</h3>
                <hr />
                <Forms
                  isDense={true}
                  editable={isEditable}
                  header={{ skip: true }}
                  permissions={["create", "read", "update", "delete"]}
                  columns={formColumns[3]}
                  getObj={user}
                  ref={(el) => (formRefs.current[3] = el)}
                  submitFunc={(values) => handleSubmit(values)}
                ></Forms>
              </Grid>
              <Grid>
                {!isEditable && (
                  <Button
                    variant={"contained"}
                    size={"small"}
                    startIcon={<EditIcon />}
                    color={"warning"}
                    onClick={() => {
                      handleEdit();
                    }}
                    sx={{ mr: 3 }}
                  >
                    Szerkesztés
                  </Button>
                )}
                {saving && (
                  <LinearProgressWithLabel value={"Mentés folyamatban..."} />
                )}
                {isEditable && !saving && (
                  <>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      startIcon={<SaveIcon />}
                      color={"success"}
                      onClick={() => {
                        handleSave();
                      }}
                      sx={{ mr: 3 }}
                    >
                      Mentés
                    </Button>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      startIcon={<EditIcon />}
                      color={"warning"}
                      onClick={() => {
                        handleEdit();
                      }}
                      sx={{ mr: 3 }}
                    >
                      Mégsem
                    </Button>
                  </>
                )}
                {error && <Alert severity="error" sx={{mt: 3}}>{error}</Alert>}
              </Grid>
            </Grid>
          )}
          {!isForm && (
            <Lists
              header={header}
              permissions={permissions}
              isDictionary={true}
              getFunc={() => getEmployees({},
                  [],
                  25,
                  0,
                  [], true
              )}
              // getCountFunc={getEmployeeCount}
              deleteFunc={deleteEmployee}
              columns={columns}
              offices={offices}
              createButtons={
                <Button
                  variant={"contained"}
                  color={"error"}
                  onClick={() => handleOpenNew()}
                >
                  Felhasználó létrehozása
                </Button>
              }
            ></Lists>
          )}
        </>
      )}
    </>
  );
}

export default Employees;
