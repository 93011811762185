import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, IconButton, MenuItem, Paper, Select } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import FilterText from "./FilterText";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import FilterSelect from "./FilterSelect";
import {
  computerSkillLevels,
  employmentStatuses,
  languageLevels,
  studentEmploymentStatus, userStatuses,
  workExperienceHc,
  workExperienceMd,
} from "../../../services/constants";
import FilterLanguage from "./FilterLanguage";
import FilterInterval from "./FilterInterval";
import moment from "moment";
import FilterHighestQualification from "./FilterHighestQualification";
import FilterQualification from "./FilterQualification";
import FilterCity from "./FilterCity";
import FilterNationality from "./FilterNationality";
import FilterWorkExperienceMd from "./FilterWorkExperienceMd";
import FilterFieldsOfWork from "./FilterFieldsOfWork";
import FilterWorkExperienceHc from "./FilterWorkExperienceHc";
import FilterCounty from "./FilterCounty";
import FilterPreferences from "./FilterPreferences";
import FilterDateInterval from "./FilterDateInterval";
import FilterComputerSkill from "./FilterComputerSkill";
import FilterAvailabilty from "./FilterAvailabilty";

Filters.propTypes = {};

function Filters(props) {
  const { site, addFilters, options } = props;

  const [loaded, setLoaded] = useState(false);

  const [possibleFilters, setPossibleFilters] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState("");

  const refFilters = useRef({});

  useEffect(() => {
    if (Object.keys(options).length > 0) {
      setLoaded(true);
    }
    return () => {
      setLoaded(false);
    };
  }, [options]);

  useEffect(() => {
    if (loaded) {
      let currentFilters = [];

      currentFilters.push({
        field: "name",
        name: "Név",
        type: "text",
        getFilter: (value) => {
          return {
            or: [
              { firstName: { like: `%${value}%` } },
              { lastName: { like: `%${value}%` } },
            ],
          };
        },
      });

      currentFilters.push({
        field: "email",
        name: "E-mail cím",
        type: "text",
      });

      if (site === "md") {
        currentFilters.push({
          field: "officeId",
          name: "Iroda",
          type: "select",
          options: options.offices,
          getFilter: (value) => {
            return { userMd: { officeId: value } };
          },
        });

        currentFilters.push({
          field: "availabilityMds",
          name: "Ráérés",
        });
      }

      currentFilters.push({
        field: "sex",
        name: "Nem",
        type: "select",
        options: [
          { id: 1, name: "Férfi" },
          { id: 2, name: "Nő" },
        ],
      });

      currentFilters.push({
        field: "nativeLanguageId",
        name: "Anyanyelv",
        type: "select",
        options: options.languages,
        getFilter: (value) => {
          if (site === "hc") {
            return { userHc: { nativeLanguageId: value } };
          } else if (site === "md") {
            return { userMd: { nativeLanguageId: value } };
          }
          return null;
        },
      }); // anyanyelv

      currentFilters.push({
        field: "language",
        name: "Nyelvtudás",
        type: "language",
        options: options.languages,
        levels: languageLevels,
        getFilter: (values, operator) => {
          const filter = { userLanguages: {} };
          filter.userLanguages[operator] = values;
          return filter;
        },
      }); // nyelvtudas

      if (site === "md") {
        currentFilters.push({
          field: "studentEmploymentStatus",
          name: "Munkavállalói státusz",
          type: "select",
          options: studentEmploymentStatus,
          getFilter: (value) => {
            return { userMd: { studentEmploymentStatus: value } };
          },
        }); // munkavallaloi statusz
      } else if (site === "hc") {
        currentFilters.push({
          field: "employmentStatus",
          name: "Munkavállalói státusz",
          type: "select",
          options: employmentStatuses,
          getFilter: (value) => {
            return { userHc: { employmentStatus: value } };
          },
        }); // munkavallaloi statusz
      }

      currentFilters.push({
        field: "age",
        name: "Életkor",
        type: "interval",
        getFilter: (valueArr) => {
          let from = isNaN(parseInt(valueArr[0])) ? 0 : parseInt(valueArr[0]);
          let to = isNaN(parseInt(valueArr[1])) ? 100 : parseInt(valueArr[1]);
          if (from > to) {
            const tmp = to;
            to = from;
            from = tmp;
          }
          return {
            birthDate: {
              between: [
                moment().subtract(to, "years"),
                moment().subtract(from, "years"),
              ],
            },
          };
        },
      }); // eletkor

      if (site === "hc") {
        // highestQualificationId
        currentFilters.push({
          field: "highestQualificationId",
          name: "Legmagasabb iskolai végzettség",
          options: options.qualifications,
        }); // legmagasabb iskolai vegzettseg
      }

      currentFilters.push({
        field: "qualification",
        name: "Iskolai végzettség",
        options: options.qualifications,
      }); // iskolai vegzettseg

      currentFilters.push({
        field: "computerSkill",
        name: "Számítógépes ismeretek",
        levels: computerSkillLevels,
        computerSkillCategories: options.computerSkillCategories,
        getFilter: (values, operator) => {
          const filter = { userComputerSkills: {} };
          filter.userComputerSkills[operator] = values;
          return filter;
        },
      }); // szamitogepes ismeretek

      currentFilters.push({
        field: "drivingLicence",
        name: "Jogosítvány",
        type: "select",
        options: options.drivingLicences,
        getFilter: (value) => {
          return { userDrivingLicences: { drivingLicenceId: value } };
        },
      }); // jogositvany

      currentFilters.push({
        field: "zip",
        name: "Irányítószám",
        type: "text",
        exact: true,
        getFilter: (value) => {
          if (site === "hc") {
            return { userHc: { zip: value } };
          } else if (site === "md") {
            return { userMd: { zip: value } };
          }
          return null;
        },
      }); // iranyitoszam

      currentFilters.push({
        field: "city",
        name: "Település",
        site: site,
      }); // telepules

      if (site === "hc") {
        currentFilters.push({
          field: "nationality",
          name: "Állampolgárság",
        }); // allampolgarsag
      }

      if (site === "hc") {
        currentFilters.push({
          field: "workExperience",
          name: "Munkatapasztalat",
          type: "select",
          options: workExperienceHc,
          getFilter: (value) => {
            return { userHc: { workExperience: value } };
          },
        }); // munkatapasztalat

        currentFilters.push({
          field: "fieldsOfWorks",
          name: "Foglalkozási területek",
        }); // foglalkozasi teruletek

        currentFilters.push({
          field: "workExperienceHc",
          name: "Munkahely, beosztás",
        }); // munkahely, beosztas

        // currentFilters.push(); // fizetesi igeny

        currentFilters.push({
          field: "county",
          name: "Megye",
          options: options.counties,
          getFilter: (value) => {
            if (site === "hc") {
              return { userHc: { countyId: { inq: value } } };
            } else if (site === "md") {
              return { userMd: { countyId: { inq: value } } };
            }
            return null;
          },
        }); // megye

        currentFilters.push({
          field: "preferences",
          name: "Elvárások",
          counties: options.counties,
          fieldsOfWorkCategories: options.fieldsOfWorkCategories,
        }); // erdeklodesi terulet

        // currentFilters.push(); // egyeb  (Munkába állás ideje, Hajlandó utazni, Hajlandó külföldön dolgozni)
      }

      if (site === "md") {
        currentFilters.push({
          field: "workExperienceMd",
          name: "Munkatapasztalat",
          options: workExperienceMd,
          workTypeMds: options.workTypeMds,
          getFilter: (value) => {
            return { userMd: { workExperience: value } };
          },
        }); // munkatapasztalat
      }

      currentFilters.push({
        field: "lastLoginTime",
        name: "Utolsó belépés",
        type: "dateInterval",
      }); // utolso belepes

      currentFilters.push({
        field: "createTime",
        name: "Regisztráció",
        type: "dateInterval",
      }); // regisztracio

      currentFilters.push({
        field: "refererId",
        name: "Hol hallott rólunk?",
        type: "select",
        options: options.referers,
        getFilter: (value) => {
          if (site === "hc") {
            return { userHc: { refererId: value } };
          } else if (site === "md") {
            return { userMd: { refererId: value } };
          }
          return null;
        },
      }); // hol hallott rolunk?

      // currentFilters.push(); // nem befejezett regisztracio

      currentFilters.push({
        field: "position",
        name: "Pozíció",
        type: "text",
        helperText: "A mezőbe a kívánt pozíció azonosítója (ID) írandó.",
        exact: true,
        getFilter: (value) => {
          if (value) {
            return { userPositions: { positionId: value } };
          }
          return null;
        },
      }); // pozicio

      if (site === "md") {
        currentFilters.push({
          field: "isSimpleRegistration",
          name: "Regisztráció típusa?",
          type: "select",
          options: [
            { id: -1, name: "Válasszon" },
            { id: 0, name: "Normál" },
            { id: 1, name: "Beugrós" },
          ],
          getFilter: (value) => {
            if (value === 1) {
              return { isSimpleRegistration: true };
            } else if (value === 0) {
              return { isSimpleRegistration: false };
            }
            return null;
          },
        }); // regisztarcio tipusa
      }

      // currentFilters.push(); // profession

      currentFilters.push({
        field: "status",
        name: "Státusz",
        type: "select",
        options: userStatuses,
        getFilter: (value) => {
          return { status: value };
        },
      }); // statusz

      setPossibleFilters(currentFilters);
    }
    return () => {
      setPossibleFilters([]);
    };
  }, [loaded]);

  useEffect(() => {
    handleSearch();
  }, [filters]);

  const handleAddFilter = () => {
    setFilters([...filters, selected]);
    setSelected("");
  };

  const handleDeleteAllFilter = () => {
    setFilters([]);
  };

  const handleDeleteFilter = (filter) => {
    if (filter?.field && filters.find((item) => item === filter.field)) {
      setFilters(filters.filter((item) => item !== filter.field));
    }
  };

  const handleSearch = () => {
    let where = {};
    Object.keys(refFilters.current).map((attr) => {
      const refFilter = refFilters.current[attr];
      if (refFilter?.getFilter) {
        const currentWhere = refFilter.getFilter();
        if (currentWhere) {
          where = { ...where, ...currentWhere };
        }
      }
    });
    addFilters(where);
  };

  return (
    <>
      {loaded && (
        <>
          <Box sx={{ ml: 4, mb: 2 }}>
            <Grid container spacing={3} alignItems={"center"}>
              <Grid>
                <Select
                  sx={{ minWidth: 200 }}
                  value={selected}
                  onChange={(event) => setSelected(event.target.value)}
                >
                  {possibleFilters
                    .filter((item) => !filters.includes(item.field))
                    .map((filterParams) => (
                      <MenuItem
                        key={filterParams.field}
                        value={filterParams.field}
                      >
                        {filterParams.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid>
                <Button
                  variant={"contained"}
                  size={"small"}
                  startIcon={<FilterAltIcon />}
                  color={"success"}
                  disabled={!selected}
                  onClick={() => {
                    handleAddFilter();
                  }}
                  sx={{ p: 1 }}
                >
                  Szűrőfeltétel hozzáadása
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant={"contained"}
                  size={"small"}
                  startIcon={<FilterAltOffIcon />}
                  color={"error"}
                  disabled={!filters.length}
                  onClick={() => {
                    handleDeleteAllFilter();
                  }}
                  sx={{ p: 1 }}
                >
                  Szűrőfeltételek törlése
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant={"contained"}
                  size={"small"}
                  startIcon={<SearchIcon />}
                  color={"primary"}
                  disabled={!filters.length}
                  onClick={() => {
                    handleSearch();
                  }}
                  sx={{ p: 1 }}
                >
                  Keresés
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ ml: 4, mb: 3 }}>
            <Grid
              container
              spacing={3}
              alignItems={"center"}
              sx={{ "& .row": { minWidth: 300 } }}
            >
              {filters.map((filterField, index) => {
                const filter = possibleFilters.find(
                  (item) => item.field === filterField
                );
                let elem = <></>;
                if (filter) {
                  switch (filter.type) {
                    case "text":
                      elem = (
                        <FilterText
                          params={filter}
                          ref={(el) => (refFilters.current[filter.field] = el)}
                        ></FilterText>
                      );
                      break;
                    case "select":
                      elem = (
                        <FilterSelect
                          params={filter}
                          ref={(el) => (refFilters.current[filter.field] = el)}
                        ></FilterSelect>
                      );
                      break;
                    case "interval":
                      elem = (
                        <FilterInterval
                          params={filter}
                          ref={(el) => (refFilters.current[filter.field] = el)}
                        ></FilterInterval>
                      );
                      break;
                    case "dateInterval":
                      elem = (
                        <FilterDateInterval
                          params={filter}
                          ref={(el) => (refFilters.current[filter.field] = el)}
                        ></FilterDateInterval>
                      );
                      break;
                    default:
                      switch (filter.field) {
                        case "language":
                          elem = (
                            <FilterLanguage
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterLanguage>
                          );
                          break;
                        case "computerSkill":
                          elem = (
                            <FilterComputerSkill
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterComputerSkill>
                          );
                          break;
                        case "highestQualificationId":
                          elem = (
                            <FilterHighestQualification
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterHighestQualification>
                          );
                          break;
                        case "qualification":
                          elem = (
                            <FilterQualification
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterQualification>
                          );
                          break;
                        case "city":
                          elem = (
                            <FilterCity
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterCity>
                          );
                          break;
                        case "nationality":
                          elem = (
                            <FilterNationality
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterNationality>
                          );
                          break;
                        case "workExperienceMd":
                          elem = (
                            <FilterWorkExperienceMd
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterWorkExperienceMd>
                          );
                          break;
                        case "fieldsOfWorks":
                          elem = (
                            <FilterFieldsOfWork
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterFieldsOfWork>
                          );
                          break;
                        case "workExperienceHc":
                          elem = (
                            <FilterWorkExperienceHc
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterWorkExperienceHc>
                          );
                          break;
                        case "county":
                          elem = (
                            <FilterCounty
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterCounty>
                          );
                          break;
                        case "county":
                          elem = (
                            <FilterPreferences
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterPreferences>
                          );
                          break;
                        case "availabilityMds":
                          elem = (
                            <FilterAvailabilty
                              params={filter}
                              ref={(el) =>
                                (refFilters.current[filter.field] = el)
                              }
                            ></FilterAvailabilty>
                          );
                          break;
                      }
                      break;
                  }
                }
                return (
                  <Grid key={`filter-${index}`}>
                    <Paper>
                      <Grid container spacing={2} alignItems={"center"}>
                        <Grid sx={{ ml: 3 }}>{elem}</Grid>
                        <Grid sx={{ mr: 2 }}>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            color={"error"}
                            onClick={() => handleDeleteFilter(filter)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default Filters;
